/* eslint-disable */ 

import axios from "axios";

const instance = axios.create({
    baseURL: 'https://yourcode-staging.com/api-yc-plataforma/public',
    headers: {
        "content-type": "application/json",
        'Authorization': 'Basic YXBpX3ljX3VzZXI6ZTZLLzc1SjMpcFl+c153TA=='
    },
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
});
 
export default {
    // SIGN IN 
    async login(data) {
        try {
            const res = await instance.post('/api/login', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    // ORÇAMENTOS
    async orcamentos() {
        try {
            const res = await instance.post('/api/orcamentos/listar');
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    async empresas() {
        try {
            const res = await instance.post('/api/empresa/listar');
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    async clientes() {
        try {
            const res = await instance.post('/api/clientes/listar');
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    async projetos() {
        try {
            const res = await instance.post('/api/projetos/listar');
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    async atualizarProposta(data) {
        try {
            const res = await instance.post('/api/orcamentos/atualizar', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    async adicionarProposta(data) {
        try {
            const res = await instance.post('/api/orcamentos/adicionar', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },

    async removerOrcamento(data) {
        try {
            const res = await instance.post('/api/orcamentos/remover', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    async atualizarUploadedFile(data) {
        try {
            const res = await instance.post('/api/orcamentos/atualizar/ficheiro', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    //AJUDAS 

    async atualizarAjudas(data) {
        try {
            const res = await instance.post('/api/ajudas/atualizar', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },

    async adicionarAjudas(data) {
        try {
            const res = await instance.post('/api/ajudas/adicionar', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },

    async removerAjudas(data) {
        try {
            const res = await instance.post('/api/ajudas/remover', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    
    // QUESTIONARIOS/BRIEFINGS 
    
        async guardarQuestionario(data) {
            try {
                const res = await instance.post('/api/questionario/guardar', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
        },
        async editarQuestionario(data) {
            try {
                const res = await instance.post('/api/questionario/editar', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
        },
        async editarQuestionarioProjetos(data) {
            try {
                const res = await instance.post('/api/questionario/projetos/editar', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
        },
        async listarQuestionarios(data) {
            try {
                const res = await instance.post('/api/briefings/listar', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
        },
        async removerQuestionario(data) {
            try {
                const res = await instance.post('/api/briefings/apagar', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
    },
        
        async verificarQuestionario(data) {
            try {
                const res = await instance.post('/api/briefings/validar', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
        },
        async guardarQuestionarioCliente(data) {
            try {
                const res = await instance.post('/api/briefings/adicionar/cliente', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
        },
        async invalidarToken(data) {
            try {
                const res = await instance.post('/api/briefings/invalidar/token', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
        },
        async criarBriefingCliente(data) {
            try {
                const res = await instance.post('/api/briefings/criar/cliente', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
    },
        
        // Objetivo 
        async guardarObjetivo(data) {
            try {
                const res = await instance.post('/api/guardar/objetivo', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
        },
        async listarObjetivo(data) {
            try {
                const res = await instance.post('/api/listar/objetivo', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
    },
        
        // CLICKUP
        async listarHorasClickUp(data) {
            try {
                const res = await instance.post('/api/listar/clickup/horas', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
        },
        async listarHorasClickUpTasks(data) {
            try {
                const res = await instance.post('/api/listar/clickup/horas/tasks', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
        },
        async clickupHorasData(data) {
            try {
                const res = await instance.post('/api/listar/clickup/horas', data);
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
        },
        
    
    
}
