<template>
  <div>
    <div class="loading-overlay" :class="show ? 'is-active' : ''">
      <span class="fas fa-spinner fa-3x fa-spin"></span>
    </div>
    <div v-if="rendered">
      <modal :show.sync="modalHoras" headerClasses="justify-content-center">
        <template>
          <!-- <div slot="header">
            <img
              src="static/img/logo-yourcode_single.png"
              style="max-width: 10%"
              alt="logo"
            />
            <span class="simple-text logo-normal modalHeader"> YourCode </span>
          </div> -->
          <div class="row text-center">
            <div class="col-6"><h5>Mês</h5></div>
            <div class="col-6"><h5>Horas gastas</h5></div>
          </div>
          <hr />
          <div class="row text-center">
            <div class="col-6">
              <span class="modalTituloDescritivo">Janeiro</span>
            </div>
            <div class="col-6">
              <span
                class="bold"
                :class="
                  Number(projetoSelecionado.janeiro) >
                  Number(projetoSelecionado.horas)
                    ? 'text-red'
                    : ''
                "
              >
                {{
                  !projetoSelecionado.janeiro
                    ? "N/A"
                    : projetoSelecionado.janeiro + " H"
                }}
              </span>
            </div>
          </div>
          <hr />
          <div class="row text-center">
            <div class="col-6">
              <span class="modalTituloDescritivo">Fevereiro</span>
            </div>
            <div class="col-6">
              <span
                class="bold"
                :class="
                  Number(projetoSelecionado.fevereiro) >
                  Number(projetoSelecionado.horas)
                    ? 'text-red'
                    : ''
                "
              >
                {{
                  !projetoSelecionado.fevereiro
                    ? "N/A"
                    : projetoSelecionado.fevereiro + " H"
                }}</span
              >
            </div>
          </div>
          <hr />
          <div class="row text-center">
            <div class="col-6">
              <span class="modalTituloDescritivo">Março</span>
            </div>
            <div class="col-6">
              <span
                class="bold"
                :class="
                  Number(projetoSelecionado.marco) >
                  Number(projetoSelecionado.horas)
                    ? 'text-red'
                    : ''
                "
              >
                {{
                  !projetoSelecionado.marco
                    ? "N/A"
                    : projetoSelecionado.marco + " H"
                }}
              </span>
            </div>
          </div>
          <hr />
          <div class="row text-center">
            <div class="col-6">
              <span class="modalTituloDescritivo">Abril</span>
            </div>
            <div class="col-6">
              <span
                class="bold"
                :class="
                  Number(projetoSelecionado.abril) >
                  Number(projetoSelecionado.horas)
                    ? 'text-red'
                    : ''
                "
              >
                {{
                  !projetoSelecionado.abril
                    ? "N/A"
                    : projetoSelecionado.abril + " H"
                }}</span
              >
            </div>
          </div>
          <hr />
          <div class="row text-center">
            <div class="col-6">
              <span class="modalTituloDescritivo">Maio</span>
            </div>
            <div class="col-6">
              <span
                class="bold"
                :class="
                  Number(projetoSelecionado.maio) >
                  Number(projetoSelecionado.horas)
                    ? 'text-red'
                    : ''
                "
              >
                {{
                  !projetoSelecionado.maio
                    ? "N/A"
                    : projetoSelecionado.maio + " H"
                }}</span
              >
            </div>
          </div>
          <hr />
          <div class="row text-center">
            <div class="col-6">
              <span class="modalTituloDescritivo">Junho</span>
            </div>
            <div class="col-6">
              <span
                class="bold"
                :class="
                  Number(projetoSelecionado.junho) >
                  Number(projetoSelecionado.horas)
                    ? 'text-red'
                    : ''
                "
              >
                {{
                  !projetoSelecionado.junho
                    ? "N/A"
                    : projetoSelecionado.junho + " H"
                }}</span
              >
            </div>
          </div>
          <hr />
          <div class="row text-center">
            <div class="col-6">
              <span class="modalTituloDescritivo">Julho</span>
            </div>
            <div class="col-6">
              <span
                class="bold"
                :class="
                  Number(projetoSelecionado.julho) >
                  Number(projetoSelecionado.horas)
                    ? 'text-red'
                    : ''
                "
              >
                {{
                  !projetoSelecionado.julho
                    ? "N/A"
                    : projetoSelecionado.julho + " H"
                }}</span
              >
            </div>
          </div>
          <hr />
          <div class="row text-center">
            <div class="col-6">
              <span class="modalTituloDescritivo">Agosto</span>
            </div>
            <div class="col-6">
              <span
                class="bold"
                :class="
                  Number(projetoSelecionado.agosto) >
                  Number(projetoSelecionado.horas)
                    ? 'text-red'
                    : ''
                "
              >
                {{
                  !projetoSelecionado.agosto
                    ? "N/A"
                    : projetoSelecionado.agosto + " H"
                }}</span
              >
            </div>
          </div>
          <hr />
          <div class="row text-center">
            <div class="col-6">
              <span class="modalTituloDescritivo">Setembro</span>
            </div>
            <div class="col-6">
              <span
                class="bold"
                :class="
                  Number(projetoSelecionado.setembro) >
                  Number(projetoSelecionado.horas)
                    ? 'text-red'
                    : ''
                "
              >
                {{
                  !projetoSelecionado.setembro
                    ? "N/A"
                    : projetoSelecionado.setembro + " H"
                }}</span
              >
            </div>
          </div>
          <hr />
          <div class="row text-center">
            <div class="col-6">
              <span class="modalTituloDescritivo">Outubro</span>
            </div>
            <div class="col-6">
              <span
                class="bold"
                :class="
                  Number(projetoSelecionado.outubro) >
                  Number(projetoSelecionado.horas)
                    ? 'text-red'
                    : ''
                "
              >
                {{
                  !projetoSelecionado.outubro
                    ? "N/A"
                    : projetoSelecionado.outubro + " H"
                }}</span
              >
            </div>
          </div>
          <hr />
          <div class="row text-center">
            <div class="col-6">
              <span class="modalTituloDescritivo">Novembro</span>
            </div>
            <div class="col-6">
              <span
                class="bold"
                :class="
                  Number(projetoSelecionado.novembro) >
                  Number(projetoSelecionado.horas)
                    ? 'text-red'
                    : ''
                "
              >
                {{
                  !projetoSelecionado.novembro
                    ? "N/A"
                    : projetoSelecionado.novembro + " H"
                }}</span
              >
            </div>
          </div>
          <hr />
          <div class="row text-center">
            <div class="col-6">
              <span class="modalTituloDescritivo">Dezembro</span>
            </div>
            <div class="col-6">
              <span
                class="bold"
                :class="
                  Number(projetoSelecionado.dezembro) >
                  Number(projetoSelecionado.horas)
                    ? 'text-red'
                    : ''
                "
              >
                {{
                  !projetoSelecionado.dezembro
                    ? "N/A"
                    : projetoSelecionado.dezembro + " H"
                }}</span
              >
            </div>
          </div>
          <hr />
        </template>
      </modal>
      <div class="row">
        <div class="col-md-12 card">
          <div class="card-body row">
            <div class="col-sm-4">
              <el-select
                class="select-danger"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-danger"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-4">
              <p-button
                @click="export_excel()"
                slot="footer"
                type="warning"
                block
                class="mb-3"
                >Download XLSX</p-button
              >
            </div>
            <div class="col-sm-4">
              <div class="pull-right">
                <fg-input
                  class="input-sm"
                  placeholder="Pesquisa"
                  v-model="searchQuery"
                  addon-right-icon="nc-icon nc-zoom-split"
                >
                </fg-input>
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table
                :data="queriedData"
                border
                style="width: 100%"
                :row-class-name="tableRowClassName"
              >
                >
                <el-table-column min-width="150" label="Cliente">
                  <div
                    slot-scope="props"
                    class="pointer"
                    @click="mostrarHoras(props.row)"
                  >
                    {{ props.row.cliente }}
                  </div>
                </el-table-column>

                <el-table-column min-width="150" label="Projeto">
                  <div
                    slot-scope="props"
                    class="pointer"
                    @click="mostrarHoras(props.row)"
                  >
                    {{ props.row.proposta }}
                  </div>
                </el-table-column>
                <el-table-column min-width="150" label="Avença">
                  <div
                    slot-scope="props"
                    class="pointer"
                    @click="mostrarHoras(props.row)"
                  >
                    {{ props.row.avenca }}
                  </div>
                </el-table-column>
                <el-table-column min-width="150" label="Horas">
                  <div
                    slot-scope="props"
                    class="pointer"
                    @click="mostrarHoras(props.row)"
                  >
                    {{ props.row.horas }}
                  </div>
                </el-table-column>
                <el-table-column min-width="150" label="Tempo este mês">
                  <div
                    slot-scope="props"
                    class="pointer"
                    @click="mostrarHoras(props.row)"
                  >
                    {{ props.row.tempoTotal }}
                  </div>
                </el-table-column>
                <el-table-column min-width="150" label="Diferença €">
                  <div
                    slot-scope="props"
                    class="pointer"
                    @click="mostrarHoras(props.row)"
                  >
                    <span
                      :class="
                        mostrarDiferenca(props.row) > 0
                          ? 'text-green'
                          : mostrarDiferenca(props.row) < 0
                          ? 'text-red'
                          : ''
                      "
                    >
                      {{ mostrarDiferenca(props.row) + " €" }}
                    </span>
                  </div>
                </el-table-column>
                <el-table-column min-width="150" label="Tempo total">
                  <div
                    slot-scope="props"
                    class="pointer"
                    @click="mostrarHoras(props.row)"
                  >
                    {{ props.row.total }}
                  </div>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6">
              <p-pagination
                class="pull-right"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
              >
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import CircleChartCard from "src/components/UIComponents/Cards/CircleChartCard.vue";
import ChartCard from "src/components/UIComponents/Cards/ChartCard";
import StatsCard from "src/components/UIComponents/Cards/StatsCard";
import { Badge, Modal } from "src/components/UIComponents";
import api from "@/api/index";
import { Table, TableColumn, Select, Option } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import { json2excel } from "js2excel";
import VueJsonToCsv from "vue-json-to-csv";
import JsonExcel from "vue-json-excel";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.component("downloadExcel", JsonExcel);

export default {
  components: {
    StatsCard,
    Modal,
    ChartCard,
    CircleChartCard,
    Badge,
    PPagination,
    VueJsonToCsv,
    JsonExcel,
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      modalHoras: false,
      json_fields: {
        pergunta: "pergunta",
        resposta: "resposta",
      },
      show: true,
      editado: false,
      id: "",
      anoCorrente: "",
      percentagemObjetivo: 0,
      questionarios: [],
      projetos: [],
      modalOrcamento: false,
      type: ["", "info", "success", "warning", "danger"],
      selects: {
        estados_Adjudicacao: [
          {
            value: "1",
            label: "Adjudicado",
          },
          {
            value: "2",
            label: "Por adjudicar",
          },
          {
            value: "3",
            label: "Não adjudicado",
          },
        ],
      },
      rendered: false,
      pagination: {
        perPage: 30,
        currentPage: 1,
        perPageOptions: [60, 100, 200, 500],
        total: 0,
      },
      projetoSelecionado: [],
      searchQuery: "",
      tableData: [],
      propsToSearch: ["cliente"],
    };
  },
  methods: {
    mostrarDiferenca(array) {
      var precoHora = 40;
      var avenca = array.avenca;
      var horas = Number(array.horas);
      var horasMensais = Number(array.tempoTotal);
      if (!avenca || !horas || horas == 0 || avenca == 0) {
        return "-";
      }
      return ((horas - horasMensais) * precoHora).toFixed(2);
      // console.log(array);
    },
    async export_excel() {
      this.show = true;
      var data = this.tableData;

      data.forEach((object) => {
        delete object["questionario"];
        delete object["ficheiro_orcamento"];
        delete object["ficheiro_cliente"];
        delete object["id_clickup"];
        delete object["dt_created"];
      });
      try {
        json2excel({
          data,
          name: "Lista-Avenças",
          formateDate: "yyyy-mm-dd",
        });
        this.show = false;
      } catch (e) {
        this.show = false;
        alert("export error");
      }
    },
    mostrarHoras(array) {
      this.projetoSelecionado = array;
      this.modalHoras = true;
    },
    epoch(date) {
      return Date.parse(date);
    },
    formatarTempoMili(tempo) {
      var minutes = moment.duration(tempo).minutes();
      var hours = Math.trunc(moment.duration(tempo).asHours());

      return hours + "." + minutes;
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.tempoTotal > 0) {
        if (Number(row.horas <= Number(row.tempoTotal))) {
          return "warning-row";
        } else {
          return "success-row";
        }
      } else {
        return "success-row";
      }
    },
    dateRange(startDate, endDate) {
      var start = startDate.split("-");
      var end = endDate.split("-");
      var startYear = parseInt(start[0]);
      var endYear = parseInt(end[0]);
      var dates = [];

      for (var i = startYear; i <= endYear; i++) {
        var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
        var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
        for (
          var j = startMon;
          j <= endMonth;
          j = j > 12 ? j % 12 || 11 : j + 1
        ) {
          var month = j + 1;
          var displayMonth = month < 10 ? "0" + month : month;
          dates.push([i, displayMonth, "01"].join("-"));
        }
      }
      return dates;
    },
    primeiroDia(year, month) {
      var date = new Date(year, month, 1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.toString();
      return date;
    },
    ultimoDia(year, month) {
      var date = new Date(year, month, 0);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(0);
      date.toString();
      return date;
    },
    async inicio() {
      let res = await api.orcamentos();
      res = Object.values(res);
      this.tableData = res.sort((a, b) => {
        return Number(a.id) > Number(b.id) ? -1 : 1;
      });

      this.tableData = this.tableData.filter(
        (x) => x.estado_adjudicacao == "1"
      );

      var clientes = [];
      var d = new Date();

      var anoCorrente = new Date();

      anoCorrente = anoCorrente.getFullYear();

      var inicioMeses = anoCorrente.toString() + "-" + "01" + "-" + "01";
      var fimMeses = anoCorrente.toString() + "-" + "12" + "-" + "31";
      var anos = [];
      // anos.push()
      let mes = 1;
      for (let i = 0; i < 12; i++) {
        anos.push({
          primeiro: this.epoch(this.primeiroDia(anoCorrente, i)),
          ultimo: this.epoch(this.ultimoDia(anoCorrente, mes)),
        });
        mes++;
      }

      d.setMonth(d.getMonth());
      d.setDate(1);
      d.setHours(0, 0, 0, 0);
      var d1 = new Date();

      d1.setMonth(d.getMonth() + 1);
      d1.setDate(1);
      d1.setHours(0, 0, 0, 0);

      var inicio = this.epoch(d);
      var fim = this.epoch(d1);
      var fimAno = this.epoch(anoCorrente + "-12-31");
      var jsonAnoTodo = {
        inicio: this.epoch(anoCorrente + "-01-01"),
        fim: fimAno,
      };
      var jsonDatas = {
        inicio: inicio,
        fim: fim,
      };
      let resultadoC = await api.clickupHorasData(jsonDatas);

      let resultadoAnoTodo = await api.clickupHorasData(jsonAnoTodo);
      resultadoAnoTodo = Object.values(JSON.parse(resultadoAnoTodo));

      resultadoC = Object.values(JSON.parse(resultadoC));
      for (let i = 0; i < res.length; i++) {
        if (res[i].id_clickup != null && res[i].id_clickup != "") {
          var temp = 0;
          var total = 0;
          var janeiro = 0;
          var fevereiro = 0;
          var marco = 0;
          var abril = 0;
          var maio = 0;
          var junho = 0;
          var julho = 0;
          var agosto = 0;
          var setembro = 0;
          var outubro = 0;
          var novembro = 0;
          var dezembro = 0;
          for (let ii = 0; ii < resultadoC.length; ii++) {
            for (let k = 0; k < resultadoC[ii].length; k++) {
              if (resultadoC[ii][k].task_location) {
                if (
                  resultadoC[ii][k].task_location.list_id == res[i].id_clickup
                ) {
                  if (Number(resultadoC[ii][k].duration) > 0) {
                    temp += Number(resultadoC[ii][k].duration);
                  }
                }
              }
            }
          }
          for (let iii = 0; iii < resultadoAnoTodo.length; iii++) {
            for (let kk = 0; kk < resultadoAnoTodo[iii].length; kk++) {
              if (resultadoAnoTodo[iii][kk].task_location) {
                if (
                  resultadoAnoTodo[iii][kk].task_location.list_id ==
                  res[i].id_clickup
                ) {
                  total += Number(resultadoAnoTodo[iii][kk].duration);
                  if (
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) >=
                      new Date(anos[0].primeiro) &&
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) <=
                      new Date(anos[0].ultimo)
                  ) {
                    janeiro += Number(resultadoAnoTodo[iii][kk].duration);
                  }
                  if (
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) >=
                      new Date(anos[1].primeiro) &&
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) <=
                      new Date(anos[2].primeiro)
                  ) {
                    fevereiro += Number(resultadoAnoTodo[iii][kk].duration);
                  }
                  if (
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) >=
                      new Date(anos[2].primeiro) &&
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) <=
                      new Date(anos[3].primeiro)
                  ) {
                    marco += Number(resultadoAnoTodo[iii][kk].duration);
                  }
                  if (
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) >=
                      new Date(anos[3].primeiro) &&
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) <=
                      new Date(anos[4].primeiro)
                  ) {
                    abril += Number(resultadoAnoTodo[iii][kk].duration);
                  }
                  if (
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) >=
                      new Date(anos[4].primeiro) &&
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) <=
                      new Date(anos[5].primeiro)
                  ) {
                    maio += Number(resultadoAnoTodo[iii][kk].duration);
                  }
                  if (
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) >=
                      new Date(anos[5].primeiro) &&
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) <=
                      new Date(anos[6].primeiro)
                  ) {
                    junho += Number(resultadoAnoTodo[iii][kk].duration);
                  }
                  if (
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) >=
                      new Date(anos[6].primeiro) &&
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) <=
                      new Date(anos[7].primeiro)
                  ) {
                    julho += Number(resultadoAnoTodo[iii][kk].duration);
                  }
                  if (
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) >=
                      new Date(anos[7].primeiro) &&
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) <=
                      new Date(anos[8].primeiro)
                  ) {
                    agosto += Number(resultadoAnoTodo[iii][kk].duration);
                  }
                  if (
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) >=
                      new Date(anos[8].primeiro) &&
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) <=
                      new Date(anos[9].primeiro)
                  ) {
                    setembro += Number(resultadoAnoTodo[iii][kk].duration);
                  }
                  if (
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) >=
                      new Date(anos[9].primeiro) &&
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) <=
                      new Date(anos[10].primeiro)
                  ) {
                    outubro += Number(resultadoAnoTodo[iii][kk].duration);
                  }
                  if (
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) >=
                      new Date(anos[10].primeiro) &&
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) <=
                      new Date(anos[11].primeiro)
                  ) {
                    novembro += Number(resultadoAnoTodo[iii][kk].duration);
                  }
                  if (
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) >=
                      new Date(anos[11].primeiro) &&
                    new Date(Number(resultadoAnoTodo[iii][kk].end)) <=
                      new Date(anos[11].ultimo)
                  ) {
                    dezembro += Number(resultadoAnoTodo[iii][kk].duration);
                  }
                }
              }
            }
          }
          res[i].tempoTotal = this.formatarTempoMili(temp);
          res[i].total = this.formatarTempoMili(total);
          res[i].janeiro = this.formatarTempoMili(janeiro);
          res[i].fevereiro = this.formatarTempoMili(fevereiro);
          res[i].marco = this.formatarTempoMili(marco);
          res[i].abril = this.formatarTempoMili(abril);
          res[i].maio = this.formatarTempoMili(maio);
          res[i].junho = this.formatarTempoMili(junho);
          res[i].julho = this.formatarTempoMili(julho);
          res[i].agosto = this.formatarTempoMili(agosto);
          res[i].setembro = this.formatarTempoMili(setembro);
          res[i].outubro = this.formatarTempoMili(outubro);
          res[i].novembro = this.formatarTempoMili(novembro);
          res[i].dezembro = this.formatarTempoMili(dezembro);
        } else {
          res[i].tempoTotal = 0;
        }
        clientes.push(res[i].cliente);
      }
      this.tableData = this.tableData.filter((x) => Number(x.avenca) > 0);
      this.rendered = true;
      this.show = false;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    notifyVue(verticalAlign, horizontalAlign, texto, tipo) {
      this.$notify({
        title: texto,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: tipo,
        timeout: 2000,
      });
    },

    formatarTempoMili(tempo) {
      var minutes = moment.duration(tempo).minutes();
      var hours = Math.trunc(moment.duration(tempo).asHours());

      return hours + "." + minutes;
    },

    async gravar(array) {
      let res = await api.atualizarProposta(array);
      if (res.resultado == "OK") {
        this.notifyVue(
          "top",
          "right",
          "Proposta atualizada com sucesso!",
          "success"
        );
        this.inicio();
        this.editado = false;
      } else {
        this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
      }
    },
    async adicionar(array) {
      let res = await api.adicionarProposta(array);
      if (res.resultado == "OK") {
        this.notifyVue(
          "top",
          "right",
          "Proposta adicionada com sucesso!",
          "success"
        );
        this.inicio();
      } else {
        this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
      }
    },
  },
  async mounted() {
    this.inicio();
  },

  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key]
            .toString()
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll("ã", "a")
            .replaceAll("á", "a")
            .replaceAll("à", "a")
            .replaceAll("é", "e")
            .replaceAll("è", "e")
            .replaceAll("ó", "o")
            .replaceAll("í", "i");
          if (
            rowValue.includes &&
            rowValue.includes(
              this.searchQuery
                .toString()
                .toLowerCase()
                .replaceAll(" ", "")
                .replaceAll("ã", "a")
                .replaceAll("á", "a")
                .replaceAll("à", "a")
                .replaceAll("é", "e")
                .replaceAll("è", "e")
                .replaceAll("ó", "o")
                .replaceAll("í", "i")
            )
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
};
</script>
<style>
.el-select.select-default {
  border-radius: 0px !important;
}
.modalHeader {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 1rem;
  color: #000;
  text-decoration: none;
  font-weight: 400;
  line-height: 30px;
  overflow: hidden;
}

.modalCliente {
  color: #ff4d1d;
  font-weight: bold;
  text-decoration: underline;
}

.modalTituloDescritivo {
  font-weight: bold;
  color: #ff4d1d;
  font-size: 16px;
}
.modal-dialog {
  max-width: 800px;
  margin: 1.75rem auto;
}

.textoDesc {
  font-size: 18px;
}

.editarModal {
  width: 50%;
  background-color: #68cc86;
  color: #fff;
}
.el-select-dropdown.is-multiple.select-danger,
.el-select-dropdown__item.selected.select-danger {
  color: #fff !important;
  background-color: #ef8157 !important;
}
.el-table__row.warning-row {
  background-color: #fed8b1 !important;
  background: #fed8b1 !important;
}

.pointer {
  cursor: pointer !important;
}
.bold {
  font-weight: bold;
}
</style>
