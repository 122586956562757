<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <card type="login">
                <h3 slot="header" class="header text-center">Login</h3>

                <fg-input
                  v-model="form.email"
                  addon-left-icon="nc-icon nc-single-02"
                  placeholder="Email"
                ></fg-input>

                <fg-input
                  v-model="form.password"
                  addon-left-icon="nc-icon nc-key-25"
                  placeholder="Palavra Passe"
                  type="password"
                ></fg-input>

                <br />
                <p-button
                  @click="login()"
                  slot="footer"
                  type="warning"
                  block
                  class="mb-3"
                  >Entrar</p-button
                >
              </card>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background: #000"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Checkbox, Button } from "src/components/UIComponents";
import AppNavbar from "../Layout/AppNavbar.vue";
import AppFooter from "../Layout/AppFooter";
import api from "@/api/index";

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
  },
  methods: {
    async login() {
      let resultado = await api.login(this.form);
      if (Object.values(resultado).length > 0) {
        var user_id = resultado[0].user_id;
        localStorage.setItem("yourcode-plataforma-sessao", user_id);
        var nivel = resultado[0].nivel;
        if (nivel == "1") {
          this.$router.push("/admin/overview");
          return;
        }
      }
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style></style>
