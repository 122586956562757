<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        <div class="credits ml-auto">
          <div class="copyright">
            &copy; Todos os direitos reservados
            <a href="https://yourcode.pt" target="_blank" rel="noopener"
              >Yourcode</a
            >.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "main-footer",
};
</script>
<style></style>
