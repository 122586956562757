<template>
  <div v-if="rendered">
    <div class="row">
      <div class="col-12">
        <h2>Empresas</h2>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12 card">
        <div class="card-body row">
          <div class="col-sm-4">
            <el-select
              class="select-danger"
              v-model="paginationEmpresasGrupo.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-danger"
                v-for="item in paginationEmpresasGrupo.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-4">
            <p-button
              style="margin: 0"
              @click="adicionarRow('empresas')"
              slot="footer"
              type="warning"
              block
              >Adicionar Empresa</p-button
            >
          </div>
          <div class="col-sm-4">
            <div class="pull-right">
              <fg-input
                class="input-sm"
                placeholder="Pesquisa"
                v-model="searchQueryEmpresasGrupo"
                addon-right-icon="nc-icon nc-zoom-split"
              >
              </fg-input>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table
              class="table-striped"
              :data="queriedDataEmpresasGrupo"
              border
              style="width: 100%"
            >
              <el-table-column min-width="150" label="Nome" sortable="custom">
                <div slot-scope="props">
                  <fg-input
                    v-if="props.row.edicao"
                    placeholder="Nome"
                    v-model="props.row.nome"
                  ></fg-input>
                  <span v-else>{{ props.row.nome }}</span>
                </div>
              </el-table-column>
              <el-table-column
                :min-width="30"
                fixed="right"
                class-name="td-actions text-center"
                label="Ações"
              >
                <template slot-scope="props">
                  <p-button
                    v-if="props.row.id != ''"
                    type="success"
                    size="sm"
                    icon
                    @click="atualizar(props.row, 'empresas')"
                  >
                    <i class="nc-icon nc-check-2"></i>
                  </p-button>
                  <p-button
                    v-else
                    type="success"
                    size="sm"
                    icon
                    @click="adicionar(props.row, 'empresas')"
                  >
                    <i class="nc-icon nc-check-2"></i>
                  </p-button>
                  <p-button
                    style="background-color: #fccf71"
                    size="sm"
                    icon
                    @click="editar(props.row, 'empresas')"
                  >
                    <i class="fa fa-pencil"></i>
                  </p-button>
                  <p-button
                    type="danger"
                    size="sm"
                    icon
                    @click="remover(props.row, 'empresas')"
                  >
                    <i class="nc-icon nc-simple-remove"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6">
            <p-pagination
              class="pull-right"
              v-model="paginationEmpresasGrupo.currentPage"
              :per-page="paginationEmpresasGrupo.perPage"
              :total="paginationEmpresasGrupo.total"
            >
            </p-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12">
        <h2>Clientes</h2>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12 card">
        <div class="card-body row">
          <div class="col-sm-4">
            <el-select
              class="select-danger"
              v-model="paginationClientes.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-danger"
                v-for="item in paginationClientes.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-4">
            <p-button
              style="margin: 0"
              @click="adicionarRow('clientes')"
              slot="footer"
              type="warning"
              block
              >Adicionar Cliente</p-button
            >
          </div>
          <div class="col-sm-4">
            <div class="pull-right">
              <fg-input
                class="input-sm"
                placeholder="Pesquisa"
                v-model="searchQueryClientes"
                addon-right-icon="nc-icon nc-zoom-split"
              >
              </fg-input>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table
              class="table-striped"
              :data="queriedDataClientes"
              border
              style="width: 100%"
            >
              <el-table-column min-width="150" label="Nome" sortable="custom">
                <div slot-scope="props">
                  <fg-input
                    v-if="props.row.edicao"
                    placeholder="Nome"
                    v-model="props.row.nome"
                  ></fg-input>
                  <span v-else>{{ props.row.nome }}</span>
                </div>
              </el-table-column>
              <el-table-column
                :min-width="30"
                fixed="right"
                class-name="td-actions text-center"
                label="Ações"
              >
                <template slot-scope="props">
                  <p-button
                    v-if="props.row.id != ''"
                    type="success"
                    size="sm"
                    icon
                    @click="atualizar(props.row, 'clientes')"
                  >
                    <i class="nc-icon nc-check-2"></i>
                  </p-button>
                  <p-button
                    v-else
                    type="success"
                    size="sm"
                    icon
                    @click="adicionar(props.row, 'clientes')"
                  >
                    <i class="nc-icon nc-check-2"></i>
                  </p-button>
                  <p-button
                    style="background-color: #fccf71"
                    size="sm"
                    icon
                    @click="editar(props.row, 'clientes')"
                  >
                    <i class="fa fa-pencil"></i>
                  </p-button>
                  <p-button
                    type="danger"
                    size="sm"
                    icon
                    @click="remover(props.row, 'clientes')"
                  >
                    <i class="nc-icon nc-simple-remove"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6">
            <p-pagination
              class="pull-right"
              v-model="paginationClientes.currentPage"
              :per-page="paginationClientes.perPage"
              :total="paginationClientes.total"
            >
            </p-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12">
        <h2>Projetos</h2>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12 card">
        <div class="card-body row">
          <div class="col-sm-4">
            <el-select
              class="select-danger"
              v-model="paginationProjetos.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-danger"
                v-for="item in paginationProjetos.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-4">
            <p-button
              style="margin: 0"
              @click="adicionarRow('projetos')"
              slot="footer"
              type="warning"
              block
              >Adicionar Projeto</p-button
            >
          </div>
          <div class="col-sm-4">
            <div class="pull-right">
              <fg-input
                class="input-sm"
                placeholder="Pesquisa"
                v-model="searchQueryProjetos"
                addon-right-icon="nc-icon nc-zoom-split"
              >
              </fg-input>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table
              class="table-striped"
              :data="queriedDataProjetos"
              border
              style="width: 100%"
            >
              <el-table-column min-width="150" label="Nome" sortable="custom">
                <div slot-scope="props">
                  <fg-input
                    v-if="props.row.edicao"
                    placeholder="Nome"
                    v-model="props.row.nome"
                  ></fg-input>
                  <span v-else>{{ props.row.nome }}</span>
                </div>
              </el-table-column>
              <el-table-column
                :min-width="30"
                fixed="right"
                class-name="td-actions text-center"
                label="Ações"
              >
                <template slot-scope="props">
                  <p-button
                    v-if="props.row.id != ''"
                    type="success"
                    size="sm"
                    icon
                    @click="atualizar(props.row, 'projetos')"
                  >
                    <i class="nc-icon nc-check-2"></i>
                  </p-button>
                  <p-button
                    v-else
                    type="success"
                    size="sm"
                    icon
                    @click="adicionar(props.row, 'projetos')"
                  >
                    <i class="nc-icon nc-check-2"></i>
                  </p-button>
                  <p-button
                    style="background-color: #fccf71"
                    size="sm"
                    icon
                    @click="editar(props.row, 'projetos')"
                  >
                    <i class="fa fa-pencil"></i>
                  </p-button>
                  <p-button
                    type="danger"
                    size="sm"
                    icon
                    @click="remover(props.row, 'projetos')"
                  >
                    <i class="nc-icon nc-simple-remove"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6">
            <p-pagination
              class="pull-right"
              v-model="paginationProjetos.currentPage"
              :per-page="paginationProjetos.perPage"
              :total="paginationProjetos.total"
            >
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import api from "@/api/index";
import Swal from "sweetalert2";
import { Table, TableColumn, Select, Option } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
export default {
  components: { PPagination },
  data() {
    return {
      rendered: false,
      paginationEmpresasGrupo: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [20, 30, 60, 200],
        total: 0,
      },
      searchQueryEmpresasGrupo: "",
      tableDataEmpresasGrupo: [],

      paginationClientes: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [20, 30, 60, 200],
        total: 0,
      },
      searchQueryClientes: "",
      tableDataClientes: [],

      paginationProjetos: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [20, 30, 60, 200],
        total: 0,
      },
      searchQueryProjetos: "",
      tableDataProjetos: [],

      propsToSearch: ["nome"],
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, texto, tipo) {
      this.$notify({
        title: texto,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: tipo,
        timeout: 2000,
      });
    },

    async inicio() {
      let resultadoEmpresas = await api.empresas();
      let resultadoClientes = await api.clientes();
      let resultadoProjetos = await api.projetos();

      this.tableDataEmpresasGrupo = Object.values(resultadoEmpresas).sort(
        (a, b) => {
          return Number(a.id) > Number(b.id) ? -1 : 1;
        }
      );
      this.tableDataClientes = Object.values(resultadoClientes).sort((a, b) => {
        return Number(a.id) > Number(b.id) ? -1 : 1;
      });
      this.tableDataProjetos = Object.values(resultadoProjetos).sort((a, b) => {
        return Number(a.id) > Number(b.id) ? -1 : 1;
      });

      for (let i = 0; i < this.tableDataEmpresasGrupo.length; i++) {
        this.tableDataEmpresasGrupo.edicao = false;
      }
      for (let i = 0; i < this.tableDataClientes.length; i++) {
        this.tableDataClientes.edicao = false;
      }
      for (let i = 0; i < this.tableDataProjetos.length; i++) {
        this.tableDataProjetos.edicao = false;
      }

      this.rendered = true;
    },

    async adicionar(array, qual) {
      array.qual = qual;
      let res = await api.adicionarAjudas(array);
      if (res.resultado == "OK") {
        this.notifyVue(
          "top",
          "right",
          "Item atualizado com sucesso!",
          "success"
        );
        this.inicio();
      } else {
        this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
      }
      return;
    },

    adicionarRow(qual) {
      if (qual == "projetos") {
        this.tableDataProjetos.unshift({
          id: "",
          nome: "",
          edicao: true,
        });
        return;
      }
      if (qual == "empresas") {
        this.tableDataEmpresasGrupo.unshift({
          id: "",
          nome: "",
          edicao: true,
        });
        return;
      }
      if (qual == "clientes") {
        this.tableDataClientes.unshift({
          id: "",
          nome: "",
          edicao: true,
        });
        return;
      }
    },

    editar(array, qual) {
      if (qual == "projetos") {
        var index = this.tableDataProjetos.indexOf(array);
        this.tableDataProjetos[index].nome =
          this.tableDataProjetos[index].nome + " ";
        this.tableDataProjetos[index].edicao =
          !this.tableDataProjetos[index].edicao;
        return;
      }
      if (qual == "empresas") {
        var index = this.tableDataEmpresasGrupo.indexOf(array);
        this.tableDataEmpresasGrupo[index].nome =
          this.tableDataEmpresasGrupo[index].nome + " ";
        this.tableDataEmpresasGrupo[index].edicao =
          !this.tableDataEmpresasGrupo[index].edicao;
        return;
      }
      if (qual == "clientes") {
        var index = this.tableDataClientes.indexOf(array);
        this.tableDataClientes[index].nome =
          this.tableDataClientes[index].nome + " ";
        this.tableDataClientes[index].edicao =
          !this.tableDataClientes[index].edicao;
        return;
      }
    },

    async atualizar(array, qual) {
      if (qual == "projetos") {
        array.nome = array.nome.trim();
        array.qual = "projetos";
        let res = await api.atualizarAjudas(array);
        if (res.resultado == "OK") {
          this.notifyVue(
            "top",
            "right",
            "Projeto atualizado com sucesso!",
            "success"
          );
          this.inicio();
        } else {
          this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
        }
        return;
      }
      if (qual == "empresas") {
        array.nome = array.nome.trim();
        array.qual = "empresas";
        let res = await api.atualizarAjudas(array);
        if (res.resultado == "OK") {
          this.notifyVue(
            "top",
            "right",
            "Empresa atualizada com sucesso!",
            "success"
          );
          this.inicio();
        } else {
          this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
        }
        return;
      }
      if (qual == "clientes") {
        array.nome = array.nome.trim();
        array.qual = "clientes";
        let res = await api.atualizarAjudas(array);
        if (res.resultado == "OK") {
          this.notifyVue(
            "top",
            "right",
            "Cliente atualizado com sucesso!",
            "success"
          );
          this.inicio();
        } else {
          this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
        }

        return;
      }
    },

    async remover(array, qual) {
      array.qual = qual;
      var _this = this;
      const swalWithBootstrapButtons4 = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons4
        .fire({
          title: "Atenção",
          text: `Tens a certeza que queres remover este item?`,
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
        })
        .then(async (result) => {
          if (result.value) {
            let res = await api.removerAjudas(array);
            if (res.resultado == "OK") {
              _this.notifyVue(
                "top",
                "right",
                "Item removido com sucesso!",
                "success"
              );
              _this.inicio();
            } else {
              _this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
            }
          }
        });
    },
  },
  mounted() {
    this.inicio();
  },

  computed: {
    // TABELA EmpresasGrupo

    pagedDataEmpresasGrupo() {
      return this.tableDataEmpresasGrupo.slice(
        this.fromEmpresasGrupo,
        this.toEmpresasGrupo
      );
    },
    queriedDataEmpresasGrupo() {
      if (!this.searchQueryEmpresasGrupo) {
        this.paginationEmpresasGrupo.total = this.tableDataEmpresasGrupo.length;
        return this.pagedDataEmpresasGrupo;
      }
      let result = this.tableDataEmpresasGrupo.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key]
            .toString()
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll("ã", "a")
            .replaceAll("á", "a")
            .replaceAll("à", "a")
            .replaceAll("é", "e")
            .replaceAll("è", "e")
            .replaceAll("ó", "o")
            .replaceAll("í", "i");
          if (
            rowValue.includes &&
            rowValue.includes(
              this.searchQueryEmpresasGrupo
                .toString()
                .toLowerCase()
                .replaceAll(" ", "")
                .replaceAll("ã", "a")
                .replaceAll("á", "a")
                .replaceAll("à", "a")
                .replaceAll("é", "e")
                .replaceAll("è", "e")
                .replaceAll("ó", "o")
                .replaceAll("í", "i")
            )
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.paginationEmpresasGrupo.total = result.length;
      return result.slice(this.fromEmpresasGrupo, this.toEmpresasGrupo);
    },
    toEmpresasGrupo() {
      let highBound =
        this.fromEmpresasGrupo + this.paginationEmpresasGrupo.perPage;
      if (this.totalEmpresasGrupo < highBound) {
        highBound = this.totalEmpresasGrupo;
      }
      return highBound;
    },
    fromEmpresasGrupo() {
      return (
        this.paginationEmpresasGrupo.perPage *
        (this.paginationEmpresasGrupo.currentPage - 1)
      );
    },
    totalEmpresasGrupo() {
      this.paginationEmpresasGrupo.total = this.tableDataEmpresasGrupo.length;
      return this.tableDataEmpresasGrupo.length;
    },
    // TABELA Clientes

    pagedDataClientes() {
      return this.tableDataClientes.slice(this.fromClientes, this.toClientes);
    },
    queriedDataClientes() {
      if (!this.searchQueryClientes) {
        this.paginationClientes.total = this.tableDataClientes.length;
        return this.pagedDataClientes;
      }
      let result = this.tableDataClientes.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key]
            .toString()
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll("ã", "a")
            .replaceAll("á", "a")
            .replaceAll("à", "a")
            .replaceAll("é", "e")
            .replaceAll("è", "e")
            .replaceAll("ó", "o")
            .replaceAll("í", "i");
          if (
            rowValue.includes &&
            rowValue.includes(
              this.searchQueryClientes
                .toString()
                .toLowerCase()
                .replaceAll(" ", "")
                .replaceAll("ã", "a")
                .replaceAll("á", "a")
                .replaceAll("à", "a")
                .replaceAll("é", "e")
                .replaceAll("è", "e")
                .replaceAll("ó", "o")
                .replaceAll("í", "i")
            )
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.paginationClientes.total = result.length;
      return result.slice(this.fromClientes, this.toClientes);
    },
    toClientes() {
      let highBound = this.fromClientes + this.paginationClientes.perPage;
      if (this.totalClientes < highBound) {
        highBound = this.totalClientes;
      }
      return highBound;
    },
    fromClientes() {
      return (
        this.paginationClientes.perPage *
        (this.paginationClientes.currentPage - 1)
      );
    },
    totalClientes() {
      this.paginationClientes.total = this.tableDataClientes.length;
      return this.tableDataClientes.length;
    },

    // TABELA Projetos

    pagedDataProjetos() {
      return this.tableDataProjetos.slice(this.fromProjetos, this.toProjetos);
    },
    queriedDataProjetos() {
      if (!this.searchQueryProjetos) {
        this.paginationProjetos.total = this.tableDataProjetos.length;
        return this.pagedDataProjetos;
      }
      let result = this.tableDataProjetos.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key]
            .toString()
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll("ã", "a")
            .replaceAll("á", "a")
            .replaceAll("à", "a")
            .replaceAll("é", "e")
            .replaceAll("è", "e")
            .replaceAll("ó", "o")
            .replaceAll("í", "i");
          if (
            rowValue.includes &&
            rowValue.includes(
              this.searchQueryProjetos
                .toString()
                .toLowerCase()
                .replaceAll(" ", "")
                .replaceAll("ã", "a")
                .replaceAll("á", "a")
                .replaceAll("à", "a")
                .replaceAll("é", "e")
                .replaceAll("è", "e")
                .replaceAll("ó", "o")
                .replaceAll("í", "i")
            )
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.paginationProjetos.total = result.length;
      return result.slice(this.fromProjetos, this.toProjetos);
    },
    toProjetos() {
      let highBound = this.fromProjetos + this.paginationProjetos.perPage;
      if (this.totalProjetos < highBound) {
        highBound = this.totalProjetos;
      }
      return highBound;
    },
    fromProjetos() {
      return (
        this.paginationProjetos.perPage *
        (this.paginationProjetos.currentPage - 1)
      );
    },
    totalProjetos() {
      this.paginationProjetos.total = this.tableDataProjetos.length;
      return this.tableDataProjetos.length;
    },
  },
};
</script>
