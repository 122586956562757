<template>
  <div v-if="rendered">
    <modal :show.sync="questionario" headerClasses="justify-content-center">
      <div slot="header">
        <img
          src="static/img/logo-yourcode_single.png"
          style="max-width: 10%"
          alt="logo"
        />
        <span class="simple-text logo-normal modalHeader"> YourCode </span>
      </div>
      <div>
        <div class="row">
          <div class="col">
            <label class="col-form-label">Nome do Questionário</label>
            <fg-input placeholder="Nome" v-model="nome"></fg-input>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm-5">
            <h4>
              Primeiro Passo
              <i
                class="nc-icon nc-simple-add pointer botaoIcon"
                @click="adicionarPergunta('1')"
              ></i>
            </h4>
            <div v-for="(item, i) in perguntas" :key="i">
              <div v-for="(pergunta, ii) in item['primeiroPasso']" :key="ii">
                <div class="text-center">
                  <div class="row">
                    <div class="col-10">
                      <fg-input
                        placeholder="Pergunta"
                        v-model="pergunta.pergunta"
                      ></fg-input>
                    </div>
                    <div class="col-2">
                      <i
                        class="nc-icon nc-simple-remove pointer text-red"
                        @click="removerPerguntaAdicionar('1', pergunta)"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-5 text-center">
            <h4>
              Segundo Passo
              <i
                class="nc-icon nc-simple-add text-green pointer botaoIcon"
                @click="adicionarPergunta('2')"
              ></i>
            </h4>
            <div v-for="(item, i) in perguntas" :key="i">
              <div v-for="(pergunta, ii) in item['segundoPasso']" :key="ii">
                <div class="text-center">
                  <div class="row">
                    <div class="col-10">
                      <fg-input
                        placeholder="Pergunta"
                        v-model="pergunta.pergunta"
                      ></fg-input>
                    </div>
                    <div class="col-2">
                      <i
                        class="nc-icon nc-simple-remove pointer text-red"
                        @click="removerPerguntaAdicionar('2', pergunta)"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="guardar()">Guardar</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="danger" link>TBD</p-button>
        </div>
      </template>
    </modal>

    <modal :show.sync="verQuestionario" headerClasses="justify-content-center">
      <div slot="header">
        <img
          src="static/img/logo-yourcode_single.png"
          style="max-width: 10%"
          alt="logo"
        />
        <span class="simple-text logo-normal modalHeader"> YourCode </span>
      </div>
      <div>
        <div class="row">
          <div class="col">
            <label class="col-md-3 col-form-label">Nome do Questionário</label>
            <fg-input placeholder="Nome" v-model="nome"></fg-input>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm-6">
            <h4>
              Primeiro Passo
              <i
                class="nc-icon nc-simple-add pointer botaoIcon"
                @click="adicionarPerguntaEdicao('1')"
              ></i>
            </h4>
            <div
              v-for="(item, i) in questionarioSelecionado.perguntas"
              :key="i"
            >
              <div v-for="(pergunta, ii) in item['primeiroPasso']" :key="ii">
                <div>
                  <div class="text-center">
                    <div class="row">
                      <div class="col-10">
                        <fg-input
                          placeholder="Pergunta"
                          v-model="pergunta.pergunta"
                        ></fg-input>
                      </div>
                      <div class="col-2">
                        <i
                          class="nc-icon nc-simple-remove pointer text-red"
                          @click="removerPergunta('1', pergunta)"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <h4>
              Segundo Passo
              <i
                class="nc-icon nc-simple-add pointer botaoIcon"
                @click="adicionarPerguntaEdicao('2')"
              ></i>
            </h4>
            <div
              v-for="(item, i) in questionarioSelecionado.perguntas"
              :key="i"
            >
              <div v-for="(pergunta, ii) in item['segundoPasso']" :key="ii">
                <div class="text-center">
                  <div class="row">
                    <div class="col-10">
                      <fg-input
                        placeholder="Pergunta"
                        v-model="pergunta.pergunta"
                      ></fg-input>
                    </div>
                    <div class="col-2">
                      <i
                        class="nc-icon nc-simple-remove pointer text-red"
                        @click="removerPergunta('2', pergunta)"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="editar()">Editar</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="danger" link>TBD</p-button>
        </div>
      </template>
    </modal>

    <div class="row mt-5">
      <div class="col-md-12 card">
        <div class="card-body row">
          <div class="col-sm-4">
            <el-select
              class="select-danger"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-danger"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-4">
            <p-button
              @click="questionario = !questionario"
              slot="footer"
              type="warning"
              block
              class="mb-3"
              >Adicionar Questionário</p-button
            >
          </div>
          <div class="col-sm-4">
            <div class="pull-right">
              <fg-input
                class="input-sm"
                placeholder="Pesquisa"
                v-model="searchQuery"
                addon-right-icon="nc-icon nc-zoom-split"
              >
              </fg-input>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table
              class="table-striped"
              :data="queriedData"
              border
              style="width: 100%"
            >
              <el-table-column min-width="150" label="Nome">
                <div slot-scope="props">
                  {{ props.row.nome }}
                </div>
              </el-table-column>
              <el-table-column min-width="150" label="Projetos">
                <div slot-scope="props">
                  <fg-input>
                    <el-select
                      multiple
                      @change="atualizarProjetos(props.row)"
                      @remove-tag="removerTag(props.row)"
                      class="select-danger"
                      size="large"
                      v-model="props.row.id_projeto"
                      placeholder="Projetos"
                    >
                      <el-option
                        v-for="option in selects.projetos"
                        class="select-danger"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label"
                      >
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
              </el-table-column>
              <el-table-column
                :max-width="120"
                fixed="right"
                class-name="td-actions text-center"
                label="Ações"
              >
                <template slot-scope="props">
                  <p-button
                    style="background-color: #fccf71"
                    size="sm"
                    icon
                    @click="verQuestionarioFuncao(props.row)"
                  >
                    <i class="nc-icon nc-badge"></i>
                  </p-button>
                  <p-button
                    type="danger"
                    size="sm"
                    icon
                    @click="apagarQuestionario(props.row)"
                  >
                    <i class="nc-icon nc-simple-remove"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6">
            <p-pagination
              class="pull-right"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import CircleChartCard from "src/components/UIComponents/Cards/CircleChartCard.vue";
import ChartCard from "src/components/UIComponents/Cards/ChartCard";
import StatsCard from "src/components/UIComponents/Cards/StatsCard";
import { Badge, Modal } from "src/components/UIComponents";
// import Loading from "src/components/Dashboard/Layout/LoadingMainPanel.vue";
import api from "@/api/index";
import { Table, TableColumn, Select, Option } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import Swal from "sweetalert2";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
export default {
  components: {
    StatsCard,
    Modal,
    ChartCard,
    CircleChartCard,
    Badge,
    PPagination,
  },
  data() {
    return {
      selects: {
        projetos: [],
      },
      verQuestionario: false,
      rendered: false,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [20, 30, 60, 200],
        total: 0,
      },
      questionarioSelecionado: [],
      searchQuery: "",
      tableData: [],
      propsToSearch: ["nome"],
      questionario: false,
      nome: "",
      perguntas: [
        {
          primeiroPasso: [],
          segundoPasso: [],
        },
      ],
    };
  },
  methods: {
    change(event) {
      console.log("The value is: " + event);
      //HERE I can't get "event.target"
    },
    async inicio() {
      let res = await api.listarQuestionarios();
      this.tableData = Object.values(res);
      for (let i = 0; i < this.tableData.length; i++) {
        if (
          this.tableData[i].id_projeto &&
          this.tableData[i].id_projeto != ""
        ) {
          this.tableData[i].id_projeto =
            this.tableData[i].id_projeto.split(",");
        }
      }

      let resultadoProjetos = await api.projetos();
      resultadoProjetos = Object.values(resultadoProjetos);
      this.selects.projetos = [];
      for (let i = 0; i < resultadoProjetos.length; i++) {
        this.selects.projetos.push({
          label: resultadoProjetos[i].nome,
          value: resultadoProjetos[i].id,
        });
      }
      this.rendered = true;
    },
    async removerTag(array) {
      var id_projeto = array.id_projeto.toString();
      var json = {
        id: array.id,
        id_projeto: id_projeto,
      };
      let res = await api.editarQuestionarioProjetos(json);
      if (res.resultado == "OK") {
        this.notifyVue(
          "top",
          "right",
          "Questionário atualizado com sucesso!",
          "success"
        );
        this.verQuestionario = false;
        this.nome = "";
      } else {
        this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
      }
    },
    async atualizarProjetos(array) {
      if (event.target.classList.length == 2) {
        return;
      }
      var id = array.id_projeto[array.id_projeto.length - 1].toString();
      let resCheck = await api.listarQuestionarios();
      resCheck = Object.values(resCheck);
      var todosProjetos = [];
      for (let i = 0; i < resCheck.length; i++) {
        if (resCheck[i].id_projeto && resCheck[i].id_projeto != "") {
          var temp = resCheck[i].id_projeto;
          todosProjetos.push(temp);
        }
      }
      var arrayFinal = [];
      for (let i = 0; i < todosProjetos.length; i++) {
        arrayFinal.push(todosProjetos[i].split(","));
      }
      if (arrayFinal.filter((x) => x.indexOf(id) > -1).length > 0) {
        var index = this.tableData.indexOf(array);
        this.notifyVue(
          "top",
          "right",
          "Já existe um questionário para esse projeto!",
          "danger"
        );
        this.tableData[index].id_projeto.pop();
        return;
      } else {
        var id_projeto = array.id_projeto.toString();
        var json = {
          id: array.id,
          id_projeto: id_projeto,
        };
        let res = await api.editarQuestionarioProjetos(json);
        if (res.resultado == "OK") {
          this.notifyVue(
            "top",
            "right",
            "Questionário atualizado com sucesso!",
            "success"
          );
          this.verQuestionario = false;
          this.nome = "";
        } else {
          this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
        }
      }
    },
    async apagarQuestionario(array) {
      var _this = this;
      const swalWithBootstrapButtons4 = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons4
        .fire({
          title: "Atenção",
          text: `Tens a certeza que queres remover este item?`,
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
        })
        .then(async (result) => {
          if (result.value) {
            let res = await api.removerQuestionario(array);
            if (res.resultado == "OK") {
              _this.notifyVue(
                "top",
                "right",
                "Item removido com sucesso!",
                "success"
              );
              _this.inicio();
            } else {
              _this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
            }
          }
        });
    },
    notifyVue(verticalAlign, horizontalAlign, texto, tipo) {
      this.$notify({
        title: texto,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: tipo,
        timeout: 2000,
      });
    },
    async guardar() {
      var json = {
        nome: this.nome,
        perguntas: JSON.stringify(this.perguntas),
      };
      let res = await api.guardarQuestionario(json);
      if (res.resultado == "OK") {
        this.notifyVue(
          "top",
          "right",
          "Questionário adicionado com sucesso!",
          "success"
        );
        this.questionario = false;
        this.nome = "";

        this.perguntas = [
          {
            primeiroPasso: [],
            segundoPasso: [],
          },
        ];
        this.inicio();
      } else {
        this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
      }
    },
    adicionarPergunta(qual) {
      if (qual == "1") {
        this.perguntas[0].primeiroPasso.unshift({
          pergunta: "",
          resposta: "",
        });
        return;
      }
      this.perguntas[0].segundoPasso.unshift({
        pergunta: "",
        resposta: "",
      });
      return;
    },
    adicionarPerguntaEdicao(qual) {
      if (qual == "1") {
        this.questionarioSelecionado.perguntas[0].primeiroPasso.unshift({
          pergunta: "",
          resposta: "",
        });
        return;
      }
      this.questionarioSelecionado.perguntas[0].segundoPasso.unshift({
        pergunta: "",
        resposta: "",
      });
      return;
    },
    removerPergunta(qual, pergunta) {
      console.log(pergunta);
      if (qual == "1") {
        var index =
          this.questionarioSelecionado.perguntas[0].primeiroPasso.indexOf(
            pergunta
          );
        this.questionarioSelecionado.perguntas[0].primeiroPasso.splice(
          index,
          1
        );
        return;
      }
      var index =
        this.questionarioSelecionado.perguntas[0].segundoPasso.indexOf(
          pergunta
        );
      this.questionarioSelecionado.perguntas[0].segundoPasso.splice(index, 1);
      return;
    },
    removerPerguntaAdicionar(qual, pergunta) {
      if (qual == "1") {
        var index = this.perguntas[0].primeiroPasso.indexOf(pergunta);
        this.perguntas[0].primeiroPasso.splice(index, 1);
        return;
      }
      var index = this.perguntas[0].segundoPasso.indexOf(pergunta);
      this.perguntas[0].segundoPasso.splice(index, 1);
      return;
    },
    async editar() {
      var json = {
        id: this.questionarioSelecionado.id,
        nome: this.nome,
        perguntas: JSON.stringify(this.questionarioSelecionado.perguntas),
      };
      let res = await api.editarQuestionario(json);
      if (res.resultado == "OK") {
        this.notifyVue(
          "top",
          "right",
          "Questionário atualizado com sucesso!",
          "success"
        );
        this.verQuestionario = false;
        this.nome = "";

        this.inicio();
      } else {
        this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
      }
    },
    verQuestionarioFuncao(array) {
      this.questionarioSelecionado = array;
      this.nome = array.nome;
      this.questionarioSelecionado.perguntas = JSON.parse(
        this.questionarioSelecionado.perguntas
      );
      this.verQuestionario = true;
    },
  },
  mounted() {
    this.inicio();
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key]
            .toString()
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll("ã", "a")
            .replaceAll("á", "a")
            .replaceAll("à", "a")
            .replaceAll("é", "e")
            .replaceAll("è", "e")
            .replaceAll("ó", "o")
            .replaceAll("í", "i");
          if (
            rowValue.includes &&
            rowValue.includes(
              this.searchQuery
                .toString()
                .toLowerCase()
                .replaceAll(" ", "")
                .replaceAll("ã", "a")
                .replaceAll("á", "a")
                .replaceAll("à", "a")
                .replaceAll("é", "e")
                .replaceAll("è", "e")
                .replaceAll("ó", "o")
                .replaceAll("í", "i")
            )
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
};
</script>
<style>
.text-green {
  color: green;
}
.text-red {
  color: red;
}
.pointer {
  cursor: pointer;
}

.modal-dialog {
  max-width: 900px !important;
  width: 900px !important;
}
.botaoIcon {
  color: #fff;
  padding: 5px;
  background: #68cc86;
  border-radius: 4px;
}
</style>
