<template>
  <div>
    <modal :show.sync="modalObj" headerClasses="justify-content-center">
      <div class="row">
        <div class="col-6">
          <span class="modalTituloDescritivo">Avenças </span>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-5">
          <fg-input placeholder="Resposta" v-model="anoAvencas"></fg-input>
        </div>
        <div class="col-5">
          <fg-input placeholder="Resposta" v-model="objetivoAvencas"></fg-input>
        </div>
        <div class="col-2">
          <p-button
            type="default"
            style="background-color: #ef8157; margin: 0 !important"
            @click="guardarObjetivo('avencas')"
            >Gravar</p-button
          >
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <span class="modalTituloDescritivo">Projetos </span>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-5">
          <fg-input placeholder="Resposta" v-model="anoProjetos"></fg-input>
        </div>
        <div class="col-5">
          <fg-input
            placeholder="Resposta"
            v-model="objetivoProjetos"
          ></fg-input>
        </div>
        <div class="col-2">
          <p-button
            type="default"
            style="background-color: #ef8157; margin: 0 !important"
            @click="guardarObjetivo('projetos')"
            >Gravar</p-button
          >
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <span class="modalTituloDescritivo">Clientes </span>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-5">
          <fg-input placeholder="Resposta" v-model="anoClientes"></fg-input>
        </div>
        <div class="col-5">
          <fg-input
            placeholder="Resposta"
            v-model="objetivoClientes"
          ></fg-input>
        </div>
        <div class="col-2">
          <p-button
            type="default"
            style="background-color: #ef8157; margin: 0 !important"
            @click="guardarObjetivo('clientes')"
            >Gravar</p-button
          >
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <span class="modalTituloDescritivo">Valores adjudicados </span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-5">
          <fg-input
            placeholder="Resposta"
            v-model="anoValores_adjudicados"
          ></fg-input>
        </div>
        <div class="col-5">
          <fg-input
            placeholder="Resposta"
            v-model="objetivoValores_adjudicados"
          ></fg-input>
        </div>
        <div class="col-2">
          <p-button
            type="default"
            style="background-color: #ef8157; margin: 0 !important"
            @click="guardarObjetivo('valores_adjudicados')"
            >Gravar</p-button
          >
        </div>
      </div>
    </modal>
    <div :class="{ 'nav-open': $sidebar.showSidebar }">
      <notifications
        transition-name="notification-list"
        transition-mode="out-in"
      >
      </notifications>
      <router-view name="header"></router-view>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
    <label v-if="!cliente" for="inputCheck" class="fab-btn">
      <span @click="modalObj = !modalObj"
        ><i class="nc-icon nc-tap-01"></i
      ></span>
    </label>
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import "sweetalert2/dist/sweetalert2.css";
import "vue-notifyjs/themes/default.css";
import api from "@/api/index";
import { Badge, Modal } from "src/components/UIComponents";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      cliente: false,
      modalObj: false,
      objetivoValores_adjudicados: 0,
      anoValores_adjudicados: 0,
      objetivoClientes: 0,
      anoClientes: 0,
      objetivoProjetos: 0,
      anoProjetos: 0,
      objetivoAvencas: 0,
      anoAvencas: 0,
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, texto, tipo) {
      this.$notify({
        title: texto,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: tipo,
        timeout: 2000,
      });
    },
    async guardarObjetivo(tipo) {
      var anos = 0;
      var objetivo = 0;
      if (tipo == "avencas") {
        anos = this.anoAvencas;
        objetivo = this.objetivoAvencas;
      }
      if (tipo == "projetos") {
        anos = this.anoProjetos;
        objetivo = this.objetivoProjetos;
      }
      if (tipo == "clientes") {
        anos = this.anoClientes;
        objetivo = this.objetivoClientes;
      }
      if (tipo == "valores_adjudicados") {
        anos = this.anoValores_adjudicados;
        objetivo = this.objetivoValores_adjudicados;
      }
      let json = {
        ano: anos,
        objetivo: objetivo,
        tipo: tipo,
      };
      if (anos == 0 || objetivo == 0) {
        return;
      }
      let res = await api.guardarObjetivo(json);
      if (res.resultado == "OK") {
        this.notifyVue(
          "top",
          "right",
          "Objetivo adicionado com sucesso",
          "success"
        );
      } else {
        this.notifyVue(
          "top",
          "right",
          "Ocorreu um erro " + res.resultado,
          "danger"
        );
      }
    },
  },
  mounted() {
    this.cliente =
      window.location.href.indexOf("briefings-cliente") > -1 ? true : false;
    var today = new Date();

    var anoCorrente = today.getFullYear();
    this.anoClientes = anoCorrente;
    this.anoProjetos = anoCorrente;
    this.anoAvencas = anoCorrente;
    this.anoValores_adjudicados = anoCorrente;
  },
};
</script>
<style lang="css">
.select-primary.el-select .el-input input,
.select-danger.el-select .el-input input,
.select-default.el-select .el-input .el-input__icon {
  border-radius: 4px !important;
  background-color: white !important;
  border-color: #ff4f1e !important;
  border-width: 2px !important;
  color: #ff4f1e !important;
}
.btn-warning {
  background-color: #fff !important;
  color: #ff4f1e !important;
  border: solid 1px #ff4f1e !important;
}

.select-danger.el-select .el-input:hover input,
.select-danger.el-select .el-input:hover .el-input__icon {
  background-color: transparent !important;
  color: #ff4f1e !important;
}
.select-danger .el-tag,
.select-danger .el-tag.el-tag--info {
  line-height: 24px;
  background-color: #ff4f1e !important;
  border: none !important;
  border-radius: 4px !important;
}
.card label {
  color: #ff4f1e !important;
  font-weight: bold;
}
footer {
  display: none;
}
.fab-btn {
  bottom: 12px;
  right: 12px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transition: box-shadow 0.4s ease;
  background: #ff4f1e;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 1.7rem;
  font-weight: bold;
  cursor: pointer;
  position: fixed;
  z-index: 999;
}

.fab-btn span {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease;
}
.modalHeader {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 1rem;
  color: #000;
  text-decoration: none;
  font-weight: 400;
  line-height: 30px;
  overflow: hidden;
}

.modalCliente {
  color: #ff4d1d;
  font-weight: bold;
  text-decoration: underline;
}

.modalTituloDescritivo {
  font-weight: bold;
  color: #ff4d1d;
  font-size: 16px;
}
.modal-dialog {
  max-width: 800px;
  margin: 1.75rem auto;
}
.loading-overlay {
  display: none;
  background: #666;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

.loading-overlay.is-active {
  display: flex;
}
.padding-left {
  padding-left: 10px !important;
}
.wid {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.card-stats .card-body .numbers {
  text-align: left !important;
  font-size: 20px !important;
}
</style>
