<template>
  <div class="content" v-if="rendered">
    <div class="row wid mt-5">
      <div class="col-12 text-center">
        <div slot="header">
          <img
            src="static/img/logo-yourcode_single.png"
            style="max-width: 6%"
            alt="logo"
          />
        </div>
      </div>
    </div>
    <div class="row wid">
      <div class="col-12 text-center">
        <div slot="header">
          <span class="simple-text logo-normal modalHeader bold">
            YourCode
          </span>
        </div>
      </div>
    </div>
    <div
      class="row wid mt-5 d-flex justify-content-center"
      v-if="valido && !feito"
    >
      <div class="col-md-10 mr-auto ml-auto">
        <wizard @complete="wizardComplete">
          <template slot="header">
            <h3 class="card-title">Build your profile</h3>
            <h3 class="description">
              This information will let us know more about you.
            </h3>
          </template>

          <wizard-tab>
            <template slot="label">
              <i class="nc-icon nc-paper"></i>
              1º Passo
            </template>
            <div>
              <div class="row mt-5">
                <div class="col-sm-12">
                  <div v-for="(item, i) in perguntas" :key="i">
                    <div
                      v-for="(pergunta, ii) in item['primeiroPasso']"
                      :key="ii"
                    >
                      <div>
                        <div>
                          <div class="row">
                            <div class="col-12">
                              <label class="col-form-label">{{
                                pergunta.pergunta
                              }}</label>
                              <fg-input
                                placeholder="Resposta"
                                v-model="pergunta.resposta"
                              ></fg-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </wizard-tab>

          <wizard-tab>
            <template slot="label">
              <i class="nc-icon nc-paper"></i>
              2º Passo
            </template>
            <div>
              <div class="row mt-5">
                <div class="col-sm-12">
                  <div v-for="(item, i) in perguntas" :key="i">
                    <div
                      v-for="(pergunta, ii) in item['segundoPasso']"
                      :key="ii"
                    >
                      <div>
                        <div>
                          <div class="row">
                            <div class="col-12">
                              <label class="col-form-label">{{
                                pergunta.pergunta
                              }}</label>
                              <fg-input
                                placeholder="Resposta"
                                v-model="pergunta.resposta"
                              ></fg-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </wizard-tab>

          <wizard-tab>
            <template slot="label">
              <i class="nc-icon nc-check-2"></i>
              3º Passo
            </template>
            <div class="row text-center">
              <div class="col-12">
                <img class="imagemSucesso" src="static/success.png" alt="" />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h4>
                  Obrigado, terminou o seu questionário, bla bla bla, poderá
                  tambem fazer upload de um ficheiro se necessitar de mais cenas
                  aqui(botão upload), caso não queira, clique em finalizar.
                  Entraremos em contacto com a maior brevidade possivel
                </h4>
              </div>
            </div>
          </wizard-tab>
        </wizard>
      </div>
    </div>
    <div v-else-if="!valido && !feito">
      <div class="text-center card">
        <div style="height: 100px; width: 100%; padding: 25px">
          <span class="invalido">Este questionário já não está válido</span>
        </div>
      </div>
      <!-- <div class="row card">
        <div class="col">Este questionário já não está válido</div>
      </div> -->
    </div>
    <div v-else-if="feito">
      <div class="text-center card">
        <div style="height: 100px; width: 100%; padding: 25px">
          <span class="invalido">Tudo feito. Obrigado</span>
        </div>
      </div>
      <!-- <div class="row card">
        <div class="col">Este questionário já não está válido</div>
      </div> -->
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import api from "@/api/index";
import { Wizard, WizardTab } from "src/components/UIComponents";

export default {
  data() {
    return {
      projeto: "",
      token: "",
      projeto: "",

      perguntas: [],
      wizardModel: {},
      valido: false,
      rendered: false,
      feito: false,
    };
  },
  components: {
    Wizard,
    WizardTab,
  },
  methods: {
    buscarParametros(name, url) {
      if (!url) url = location.href;
      name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(url);
      return results == null ? null : results[1];
    },

    async inicio() {
      var token = this.buscarParametros("token");
      var questionario = this.buscarParametros("questionario");
      var projeto = this.buscarParametros("projeto");

      this.token = token;
      this.questionario = questionario;

      this.projeto = projeto;

      let jsonValidar = {
        token: token,
        briefing: questionario,
        orcamento: projeto,
      };

      let validarQuestionário = await api.verificarQuestionario(jsonValidar);
      if (validarQuestionário.resultado == "invalido") {
        this.valido = false;
        this.rendered = true;
        return;
      }

      //BUSCAR QUESTIONARIO
      let resultadoQuestionario = await api.listarQuestionarios();
      resultadoQuestionario = Object.values(resultadoQuestionario);
      let questionarioJson = resultadoQuestionario.filter(
        (x) => x.id == questionario
      );
      if (questionarioJson.length > 0) {
        this.perguntas = JSON.parse(questionarioJson[0].perguntas);
      } else {
        this.valido = false;
        this.rendered = true;
        return;
      }

      this.valido = true;
      this.rendered = true;
    },
    async wizardComplete() {
      //Guardar Respostas Clientes
      let jsonQuestCliente = {
        id: this.projeto,
        questionario: JSON.stringify(this.perguntas),
      };

      let res = await api.guardarQuestionarioCliente(jsonQuestCliente);
      if (res.resultado == "OK") {
        let jsonToken = {
          token: this.token,
        };
        let invalidarToken = await api.invalidarToken(jsonToken);
        if (invalidarToken.resultado == "OK") {
          this.feito = true;
          this.inicio();
        }
      }
    },
  },
  mounted() {
    this.inicio();
  },
};
</script>
<style>
.invalido {
  font-size: 24px;
  font-weight: bold;
}

.imagemSucesso {
  max-width: 200px;
}
.wid {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
</style>
