<template></template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  components: {
    CollapseTransition,
  },
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
  },
};
</script>
<style scoped>
.nav.nav-menu {
  margin-top: 0;
}
</style>
